import "bootstrap/dist/css/bootstrap-reboot.css";
import "bootstrap/dist/css/bootstrap.css";
import "./app.css";
import "bootstrap/dist/js/bootstrap.bundle";
import "bootstrap-icons/font/bootstrap-icons.css";
import React, { useEffect, useState } from "react";
import { type } from "os";

type Word = {
  text: string;
  id: string;
  date: string;
};

function App() {
  const [loading, setLoading] = useState(true);
  const [refresh, setRefresh] = useState(0);
  const [words, setWords] = useState<Word[]>([]);
  const [blockItems, setBlockItems] = useState<Word[]>([]);
  const [config, setConfig] = useState<{ [key: string]: number }>({});
  const [page, setPage] = useState("words");
  const [wordInput, setWordInput] = useState("");
  const [blockItemInput, setBlockItemInput] = useState("");

  useEffect(() => {
    fetch(process.env.REACT_APP_API_URL + "/state")
      .then((res) => res.json())
      .then((res) => {
        console.log(res);
        const { words, blockItems, ...config } = res;
        if (Array.isArray(words)) {
          setWords(
            words.map((word) => ({
              text: word.text,
              id: word._id,
              date: word.date,
            }))
          );
        }
        if (Array.isArray(blockItems)) {
          setBlockItems(
            blockItems.map((blockItem) => ({
              text: blockItem.text,
              id: blockItem._id,
              date: blockItem.date,
            }))
          );
        }
        if (typeof config === "object") {
          setConfig(config);
        }
        setLoading(false);
      })
      .catch(console.error);
  }, [refresh]);

  async function handleWordSubmit() {
    await fetch(process.env.REACT_APP_API_URL + "/word", {
      method: "POST",
      body: JSON.stringify({ text: wordInput }),
      headers: {
        "Content-Type": "application/json",
      },
    });
    incrementRefresh();
    setWordInput("");
  }

  async function handleBlockItemSubmit() {
    await fetch(process.env.REACT_APP_API_URL + "/block-item", {
      method: "POST",
      body: JSON.stringify({ text: blockItemInput }),
      headers: {
        "Content-Type": "application/json",
      },
    });
    incrementRefresh();
    setBlockItemInput("");
  }

  async function deleteWord(id: string) {
    await fetch(process.env.REACT_APP_API_URL + `/word/${id}`, {
      method: "DELETE",
    });
    incrementRefresh();
  }

  async function deleteBlockItem(id: string) {
    await fetch(process.env.REACT_APP_API_URL + `/block-item/${id}`, {
      method: "DELETE",
    });
    incrementRefresh();
  }

  function incrementRefresh() {
    setRefresh(refresh + 1);
  }

  return (
    <div>
      <nav className="navbar navbar-expand-md navbar-dark bg-dark fixed-top">
        <div className="container">
          <a className="navbar-brand" href="#">
            Zaal 10
          </a>
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarsExampleDefault"
            aria-controls="navbarsExampleDefault"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>

          <div className="collapse navbar-collapse" id="navbarsExampleDefault">
            <ul className="navbar-nav mr-auto">
              <li className={`nav-item ${page === "words" ? "active" : ""}`}>
                <a
                  className="nav-link"
                  href="#"
                  onClick={() => setPage("words")}
                >
                  Words
                </a>
              </li>
              <li className={`nav-item ${page === "config" ? "active" : ""}`}>
                <a
                  className="nav-link"
                  href="#"
                  onClick={() => setPage("config")}
                >
                  Config
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>

      <main role="main" className="container">
        <div className="app">
          <div className="row mb-5">
            <div className="col-12">
              <button
                className="btn btn-outline-secondary"
                onClick={() => incrementRefresh()}
              >
                <i className="bi bi-arrow-repeat"></i>
              </button>
            </div>
          </div>
          {page === "words" && (
            <div className="row">
              <div className="col-md-6 mb-5">
                <h2>Word List</h2>
                {loading && <div>Loading...</div>}
                {!loading && (
                  <div>
                    <div className="input-group mb-3">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Add Word"
                        aria-label="Add Word"
                        aria-describedby="button-addon2"
                        value={wordInput}
                        onChange={(e) => setWordInput(e.target.value)}
                      />
                      <div className="input-group-append">
                        <button
                          className="btn btn-primary"
                          type="button"
                          id="button-addon2"
                          onClick={() => handleWordSubmit()}
                        >
                          Add
                        </button>
                      </div>
                    </div>
                    <div className="list-group">
                      {words.map((word, i) => (
                        <div
                          key={`word-${i}`}
                          className="list-group-item list-group-item-action"
                        >
                          <div className="d-flex w-100 justify-content-between">
                            <h5 className="mb-1">{word.text}</h5>
                            <button
                              className="btn btn-outline-secondary"
                              onClick={() => deleteWord(word.id)}
                            >
                              <i className="bi bi-trash"></i>
                            </button>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </div>
              <div className="col-md-6 mb-5">
                <h2>Block List</h2>
                {loading && <div>Loading...</div>}
                {!loading && (
                  <div>
                    <div className="input-group mb-3">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Add Word"
                        aria-label="Add Word"
                        aria-describedby="button-addon2"
                        value={blockItemInput}
                        onChange={(e) => setBlockItemInput(e.target.value)}
                      />
                      <div className="input-group-append">
                        <button
                          className="btn btn-primary"
                          type="button"
                          id="button-addon2"
                          onClick={() => handleBlockItemSubmit()}
                        >
                          Add
                        </button>
                      </div>
                    </div>
                    <div className="list-group">
                      {blockItems.map((blockItem, i) => (
                        <div
                          key={`block-item-${i}`}
                          className="list-group-item list-group-item-action"
                        >
                          <div className="d-flex w-100 justify-content-between">
                            <h5 className="mb-1">{blockItem.text}</h5>
                            <button
                              className="btn btn-outline-secondary"
                              onClick={() => deleteBlockItem(blockItem.id)}
                            >
                              <i className="bi bi-trash"></i>
                            </button>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}
          {page === "config" && (
            <>
              <h2>config</h2>
            </>
          )}
        </div>
      </main>
    </div>
  );
}

export default App;
